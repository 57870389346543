<template>
  <div class="size-full">
    <h3 class="text-xl">Usage</h3>
    <div
      class="flex h-[80px] max-h-[80px] min-h-[80px] w-full flex-col items-center justify-center"
    >
      <vue-ui-sparkbar
        :config="config"
        :dataset="dataset"
        class="[&>*>svg]:overflow-hidden [&>*>svg]:rounded-lg"
      />
      <span class="w-full text-sm text-gray-500">
        {{ value }} / {{ useIntFormat(usageLimit) }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import 'vue-data-ui/style.css'
import {
  VueUiSparkbar,
  type VueUiSparkbarConfig,
  type VueUiSparkbarDatasetItem,
} from 'vue-data-ui'
import colors from 'tailwindcss/colors'
import type Stripe from 'stripe'

const props = defineProps<{
  value: number
  subscription: Stripe.Subscription | null | undefined
}>()
const isDark = useDark()
const config = computed<VueUiSparkbarConfig>(() => ({
  theme: '',
  customPalette: [colors.indigo[500]],
  style: {
    backgroundColor: 'transparent',
    fontFamily: 'inherit',
    animation: { show: true, animationFrames: 150 },
    layout: { independant: true, percentage: true, target: 0 },
    gutter: {
      backgroundColor: isDark.value ? colors.neutral[700] : colors.neutral[300],
      opacity: 100,
    },
    bar: {
      gradient: { show: false, intensity: 0, underlayerColor: '#ffffff' },
    },
    labels: {
      fontSize: 16,
      name: {
        position: 'top',
        width: '100%',
        color: isDark.value ? colors.neutral[400] : colors.neutral[600],
        bold: false,
      },
      value: { show: true, bold: true },
    },
    gap: 4,
  },
}))
const usageLimit = computed(
  () =>
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    (usePlanTiers() as any)[
      props.subscription &&
      typeof (props.subscription as unknown as Stripe.SubscriptionItem).plan
        ?.amount === 'number'
        ? ((props.subscription as unknown as Stripe.SubscriptionItem).plan
            ?.amount ?? 0) / 100
        : 0
    ],
)
const usagePercentage = computed(
  () => useProjection(() => props.value, [0, usageLimit.value], [0, 100]).value,
)
const dataset = computed<VueUiSparkbarDatasetItem[]>(() => [
  {
    name: 'Submissions',
    value: usagePercentage.value,
    rounding: '2',
    suffix: '%',
  },
])
</script>
