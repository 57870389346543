<template>
  <div class="size-full">
    <h3 class="text-xl">
      {{
        hoveredIndex === undefined
          ? 'Submissions'
          : format(parseISO(full180Days[hoveredIndex].period), 'PP')
      }}
    </h3>
    <div
      class="flex max-h-[80px] min-h-[80px] w-full items-center justify-center"
    >
      <vue-ui-sparkline
        v-if="isShowingData"
        :config="config"
        :dataset="full180Days"
        @hover-index="onHoverIndex"
      />
      <p v-else>No hosted submissions found.</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import 'vue-data-ui/style.css'
import { format, parseISO, subDays, formatISO } from 'date-fns'
import {
  VueUiSparkline,
  type VueUiSparklineConfig,
  type VueUiSparklineDatasetItem,
} from 'vue-data-ui'
import colors from 'tailwindcss/colors'

const props = withDefaults(
  defineProps<{
    dataset: VueUiSparklineDatasetItem[]
  }>(),
  {
    dataset: () => [],
  },
)
const isDark = useDark()
const isShowingData = ref(true)
const hoveredIndex = ref<number | undefined>(undefined)
const full180Days = computed(() => {
  const mergedData = Array.from({ length: 180 }, (_, i) => ({
    period: formatISO(subDays(new Date(), 179 - i), { representation: 'date' }),
    value: 0,
  }))

  for (const datum of props.dataset) {
    const index = mergedData.findIndex(({ period }) => period === datum.period)

    if (index !== -1) {
      mergedData[index].value = datum.value
    }
  }

  return mergedData
})
const config = computed<VueUiSparklineConfig>(() => ({
  type: 'line',
  style: {
    backgroundColor: 'transparent',
    fontFamily: 'inherit',
    animation: { show: true, animationFrames: 150 },
    line: { color: colors.indigo[500], strokeWidth: 3, smooth: true },
    zeroLine: { color: colors.neutral[500], strokeWidth: 1 },
    plot: { show: false },
    verticalIndicator: {
      show: true,
      strokeWidth: 1.5,
      color: colors.indigo[600],
      strokeDasharray: 3,
    },
    dataLabel: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      position: 'left',
      fontSize: 48,
      bold: true,
      color: isDark.value ? colors.neutral[400] : colors.neutral[600],
      roundingValue: 1,
      valueType: 'sum',
      prefix: '',
      suffix: '',
    },
    title: {
      show: false,
    },
    area: {
      show: true,
      useGradient: true,
      opacity: 33,
      color: colors.indigo[500],
    },
  },
}))

function onHoverIndex(event: { index: number | undefined }) {
  hoveredIndex.value = event.index
}

onMounted(() => {
  if (props.dataset.length === 0) {
    setTimeout(() => {
      isShowingData.value = false
    }, 300)
  }
})
</script>
