<template>
  <client-only>
    <page-wrapper v-if="teamStore.team && formStore.form" :padding="false">
      <template #header>
        <u-breadcrumb
          :links="[
            { label: 'Teams', to: '/u/teams' },
            { label: teamStore.team.name, to: `/${teamStore.team.name}` },
            { label: formStore.form.name },
          ]"
        />
      </template>
      <div class="flex justify-between px-4">
        <h1 class="truncate text-2xl">
          {{ formStore.form.name }}
        </h1>
        <div class="flex items-center gap-4">
          <span v-if="formStore.isPlanInactive" class="relative flex size-3">
            <span
              class="relative inline-flex size-3 rounded-full bg-red-500 dark:bg-red-400"
            />
          </span>
          <span v-else class="relative flex size-3">
            <span
              class="absolute inline-flex size-full animate-ping rounded-full bg-green-500 opacity-75 dark:bg-green-400"
            />
            <span
              class="relative inline-flex size-3 rounded-full bg-green-500 dark:bg-green-400"
            />
          </span>
          <u-button
            color="white"
            :to="`/${teamStore.team.name}/form/${formStore.form.id}/manage`"
          >
            Manage
          </u-button>
        </div>
      </div>
      <div class="space-y-4">
        <div v-if="formStore.isPlanInactive" class="px-4">
          <u-notification
            id="is-form-inactive"
            :ui="{ shadow: '' }"
            :close-button="{
              ui: {
                base: '!hidden',
              },
            }"
            icon="i-heroicons-x-circle"
            description="Please subscribe to a plan to activate this form."
            color="red"
            :timeout="0"
            title="Inactive"
            :actions="[
              {
                label: 'Manage',
                color: 'primary',
                to: `/${teamStore.team.name}/form/${formStore.form.id}/manage`,
              },
            ]"
          />
        </div>
        <div v-if="formStore.isHidden" class="px-4">
          <u-notification
            id="is-form-hidden"
            :ui="{ shadow: '' }"
            :close-button="{
              ui: {
                base: '!hidden',
              },
            }"
            icon="i-heroicons-eye-slash"
            description="This form is currently hidden from your audience."
            color="yellow"
            :timeout="0"
            title="Hidden"
          />
        </div>
        <div class="grid gap-4 px-4 md:grid-cols-2 xl:grid-cols-4">
          <u-landing-card :ui="{ body: { base: 'gap-y-0' } }">
            <div class="flex h-[104px] items-center justify-center">
              <data-submissions :dataset="formStore.usage.submissions" />
            </div>
          </u-landing-card>
          <u-landing-card :ui="{ body: { base: 'gap-y-0' } }">
            <div class="flex h-[104px] items-center justify-center">
              <data-usage
                :value="formStore.usage.value"
                :subscription="formStore.usage.subscription"
              />
            </div>
          </u-landing-card>
        </div>
      </div>
      <div class="flex gap-2 overflow-hidden overflow-x-auto px-4">
        <u-button
          variant="outline"
          :to="`/${teamStore.team.name}/form/${formStore.form.id}/data`"
        >
          View data
        </u-button>
        <u-button
          variant="outline"
          :disabled="formStore.isPrivate"
          @click="
            () =>
              formStore.form ? navigateTo(`/s/${formStore.form.id}`) : null
          "
        >
          View form
        </u-button>
        <u-button
          :color="copied ? 'green' : 'primary'"
          variant="outline"
          :disabled="formStore.isPrivate"
          @click="copyLink"
        >
          <template #leading>
            <u-icon v-if="copied" name="i-heroicons-check" />
          </template>
          {{ copied ? 'Copied!' : 'Copy link' }}
        </u-button>
        <u-button
          variant="outline"
          :to="`/${teamStore.team.name}/form/${formStore.form.id}/pipeline`"
        >
          Edit pipeline
        </u-button>
        <u-button
          variant="outline"
          :to="`/${teamStore.team.name}/form/${formStore.form.id}/integrations`"
        >
          Add integration
        </u-button>
      </div>
      <div class="mt-2 px-4">
        <page-form-editor :team="teamStore.team" :form="formStore.form" />
      </div>
    </page-wrapper>
  </client-only>
</template>

<script lang="ts" setup>
definePageMeta({
  middleware: ['auth', 'team', 'form'],
})

useHead({
  title: 'Manage form',
})

const teamStore = useTeamStore()
const formStore = useFormStore()
const { copy } = useCopyToClipboard()
const copied = refAutoReset(false, 5000)

function copyLink() {
  if (!formStore.form) {
    return
  }

  copy(`${window.location.origin}/s/${formStore.form.id}`)

  copied.value = true
}
</script>
